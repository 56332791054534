import React from 'react'
import { Box, Checkbox, Item } from '@revolut/ui-kit'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import HiringStageDetails from '@src/components/HiringStages/HiringStageDetails'

type HiringStageProps = {
  disabled: boolean
  hiringStage: HiringProcessInterface
  selected?: HiringProcessInterface[]
  onSelect?: (selected: HiringProcessInterface[]) => void
}

export const HiringStage = ({
  disabled,
  hiringStage,
  selected,
  onSelect,
}: HiringStageProps) => {
  const selectedStage = selected?.find(({ id }) => id === hiringStage.id)
  return (
    <Box key={hiringStage.id} my="s-8">
      <Item use="label">
        {onSelect && !!selected && (
          <Item.Prefix>
            <Checkbox
              disabled={disabled}
              checked={!!selectedStage}
              onClick={() => {
                onSelect(
                  selectedStage
                    ? selected.filter(({ id }) => id !== hiringStage.id)
                    : [...selected, hiringStage],
                )
              }}
            />
          </Item.Prefix>
        )}
        <Item.Content>
          <Item.Title>{hiringStage.title}</Item.Title>
          <Item.Description>
            <HiringStageDetails hiringStage={hiringStage} />
          </Item.Description>
        </Item.Content>
      </Item>
    </Box>
  )
}
