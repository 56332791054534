import React, { useMemo } from 'react'
import { useTable } from '@components/Table/hooks'
import { FilterByInterface, RowInterface, Stats } from '@src/interfaces/data'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { roadmapsExtraRequests } from '@src/api/roadmaps'
import {
  departmentColumn,
  roadmapDueDate,
  roadmapEpicUrlColumn,
  roadmapGenericNameColumn,
  roadmapProgressColumn,
  roadmapResolutionAtColumn,
  roadmapStartDate,
  roadmapStatusColumn,
  teamColumn,
} from '@src/constants/columns/roadmap'
import { ownerNameColumn } from '@src/constants/columns/employee'
import { TableWidget, MoreBar } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { useGetRoadmapSettings } from '@src/api/settings'
import { selectorKeys } from '@src/constants/api'
import { useShowRoadmapDetails, useRoadmapContext, useRoadmapRefresh } from './RoadmapTab'
import { OverallProgress } from '@src/features/Goals/components/OverallProgress'
import { foreignGoalColumn } from '@src/constants/columns/goals'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { getCommonFilters } from './common'
import { GoalCycleFilter } from '../components/GoalCycleFilter'
import { GoalsSettingsButton } from '../components/GoalsSettingsButton'
import { EntitySelector } from './EntitySelector'

const getRows = (filterBy: FilterByInterface[]): RowInterface<RoadmapInterface> => {
  const typeFilter = filterBy.find(({ columnName }) => columnName === 'type')?.filters
  let orgUnitRow: 'department' | 'team' | null
  switch (typeFilter?.[0].id) {
    case 'department_roadmap':
      orgUnitRow = 'department'
      break
    case 'team_roadmap':
      orgUnitRow = 'team'
      break
    default:
      orgUnitRow = null
  }
  return {
    cells: [
      {
        ...roadmapGenericNameColumn,
        width: 350,
      },
      {
        ...foreignGoalColumn,
        width: 200,
      },
      orgUnitRow && {
        ...(orgUnitRow === 'team' ? teamColumn : departmentColumn),
        width: 150,
      },
      {
        ...roadmapProgressColumn,
        width: 120,
      },
      {
        ...roadmapStatusColumn,
        width: 100,
      },
      {
        ...roadmapStartDate,
        width: 100,
      },
      {
        ...roadmapDueDate,
        width: 100,
      },
      {
        ...roadmapResolutionAtColumn,
        width: 100,
      },
      {
        ...ownerNameColumn,
        width: 120,
      },
      {
        ...roadmapEpicUrlColumn,
        width: 120,
      },
    ].filter(Boolean),
  }
}

export const PerformanceRoadmaps = ({
  initialCycle,
}: {
  initialCycle: ReviewCyclesInterface
}) => {
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const ctx = useRoadmapContext()
  const jiraEnabled = roadmapSettings?.jira_epics_enabled

  const initialFilter = [
    ...getCommonFilters({ reviewCycle: initialCycle }),
    {
      filters: [{ name: 'True', id: 'True' }],
      columnName: 'is_company',
      nonResettable: true,
    },
  ]

  const table = useTable<RoadmapInterface, Stats>(roadmapsExtraRequests, initialFilter)

  const { renderCount } = useRoadmapRefresh({
    epics: table.data.map(r => r.key),
    onAfterRefresh: () => table.refresh(),
  })

  const { renderSidebar, showRoadmapDetails } = useShowRoadmapDetails({
    changelogState: false,
    jiraEnabled: !!jiraEnabled,
  })

  const row = useMemo(() => getRows(table.filterBy), [table.filterBy])

  return (
    <>
      <TableWidget>
        <TableWidget.Info>
          <GoalCycleFilter
            onFilterChange={table.onFilterChange}
            columnName="review_cycle__id"
            filter={table.filterBy}
            selector={selectorKeys.review_cycles}
          />
          <OverallProgress value={table?.stats?.avg_done} />
        </TableWidget.Info>
        <TableWidget.Actions>
          {/* @TODO https://revolut.atlassian.net/browse/REVC-6311 create new roadmaps  */}
          <MoreBar>
            <GoalsSettingsButton />
          </MoreBar>
        </TableWidget.Actions>
        <TableWidget.Filters>
          <EntitySelector
            cycleFilter={table.filterBy.find(f => f.columnName === 'review_cycle__id')}
            onFilterChange={table.onFilterChange}
          />
        </TableWidget.Filters>
        <TableWidget.Table>
          <AdjustableTable<RoadmapInterface>
            name={TableNames.CompanyRoadmap}
            useWindowScroll
            row={row}
            onRowClick={showRoadmapDetails}
            {...table}
            dataType={ctx.dataType}
            pendingDataType={ctx.isLoading}
            noDataMessage={ctx.noDataMessage}
            expandableType="chevron"
            fetchChildren={(parentIndexes, id) => table.fetchChildren(parentIndexes, id)}
            renderCount={
              table?.stats?.refresh_date_time
                ? count => renderCount(count, table?.stats?.refresh_date_time!)
                : undefined
            }
          />
        </TableWidget.Table>
      </TableWidget>
      {renderSidebar({ canEdit: false })}
    </>
  )
}
