import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import {
  Box,
  HStack,
  MoreBar,
  MoreBarActionProps,
  Flex,
  IconButton,
  Color,
  useTooltip,
  Tooltip,
} from '@revolut/ui-kit'
import { Pin, Switches, Unpin } from '@revolut/icons'
import { Route, Switch } from 'react-router-dom'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import {
  FeatureFlags,
  GlobalSettings,
  PerformanceSettings,
  PermissionTypes,
} from '@src/store/auth/types'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { TabBarWithPinning } from '@src/features/TabPinning/TabBarWithPinning'
import { TableWithSubtabs } from '@src/features/TabPinning/TableWithSubtabs'
import { PIN_APP_LABEL, UNPIN_APP_LABEL, usePinnedApps } from '@src/pages/Hub/Apps/common'
import { HubAppType } from '@src/interfaces/hub'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import { NoAccessError } from '@src/features/Form/FormErrorGuard'
import { useGlobalSettings } from '@src/api/settings'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'

interface HubAppProps {
  app: HubAppType
  actions?: {
    props: MoreBarActionProps & { text: string }
    canView: PermissionTypes[]
  }[]
  loading?: boolean
}

const HubApp = ({ app, actions, loading }: HubAppProps) => {
  const { sendAnalyticsEvent } = useAnalytics()
  const featureFlags = useSelector(selectFeatureFlags)
  const permissions = useSelector(selectPermissions)
  const globalSettings = useGlobalSettings()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const settingsTooltip = useTooltip()
  const pinningTooltip = useTooltip()

  const { id, title, settingsConfig } = app
  const hasTabs = 'tabs' in app

  const permissionFilter = (entity?: {
    canView?: PermissionTypes[]
    featureFlags?: FeatureFlags[]
  }) => {
    const checkPermissions = entity?.canView
      ? entity.canView.some(permission => permissions.includes(permission))
      : true
    const checkFeatureFlags = entity?.featureFlags
      ? entity.featureFlags.some(flag => featureFlags.includes(flag))
      : true

    return checkPermissions && checkFeatureFlags
  }

  const globalSettingsFilter = (entity?: {
    globalSetting?: GlobalSettings
    performanceSetting?: PerformanceSettings
  }) => {
    const globalSettingCheck = entity?.globalSetting
      ? /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        globalSettings.settings[entity.globalSetting]
      : true
    const performanceSettingCheck = entity?.performanceSetting
      ? performanceSettings?.[entity.performanceSetting]
      : true

    return globalSettingCheck && performanceSettingCheck
  }

  const canView =
    permissionFilter('canView' in app || 'featureFlags' in app ? app : undefined) &&
    globalSettingsFilter('globalSetting' in app ? app : undefined)
  const filteredByGlobalSettingsTabs = hasTabs
    ? app.tabs.filter(globalSettingsFilter)
    : null
  const filteredTabs = filteredByGlobalSettingsTabs
    ? filteredByGlobalSettingsTabs.filter(permissionFilter)
    : null
  const filteredActions = actions?.filter(permissionFilter)

  const { pinnedApps, setPinnedApps } = usePinnedApps()

  const isPinned = pinnedApps.includes(id)

  const canViewSettings =
    settingsConfig &&
    settingsConfig.canView.some(permission => permissions.includes(permission))

  const handlePinning = () => {
    if (!isPinned) {
      sendAnalyticsEvent(AnalyticsEvents.pin_app, { app_id: id })
      setPinnedApps(prev => [...prev, id])
      return
    }

    sendAnalyticsEvent(AnalyticsEvents.unpin_app, { app_id: id })
    setPinnedApps(prev => prev.filter(pinnedAppId => pinnedAppId !== id))
  }

  if (loading) {
    return <PageLoading />
  }

  if ((filteredTabs !== null && !filteredTabs.length) || !canView) {
    return <NoAccessError />
  }

  const hideSingleTab =
    'hideSingleTab' in app &&
    app.hideSingleTab &&
    filteredTabs &&
    filteredTabs?.length < 2

  return (
    <PageWrapper>
      <PageHeader
        pb="s-8"
        title={
          <HStack align="center" space="s-8">
            <Box pr="s-8">{title}</Box>
            {canViewSettings ? (
              <>
                <IconButton
                  useIcon={Switches}
                  color={Color.GREY_TONE_50}
                  use={InternalLink}
                  to={pathToUrl(settingsConfig.path)}
                  aria-label="app settings"
                  {...settingsTooltip.getAnchorProps()}
                  size={16}
                />
                <Tooltip {...settingsTooltip.getTargetProps()}>
                  {settingsConfig.title} settings
                </Tooltip>
              </>
            ) : null}
            <>
              <IconButton
                onClick={handlePinning}
                useIcon={isPinned ? Pin : Unpin}
                color={Color.GREY_TONE_50}
                aria-label={isPinned ? UNPIN_APP_LABEL : PIN_APP_LABEL}
                size={20}
                {...pinningTooltip.getAnchorProps()}
              />
              <Tooltip {...pinningTooltip.getTargetProps()}>
                {isPinned ? UNPIN_APP_LABEL : PIN_APP_LABEL}
              </Tooltip>
            </>
          </HStack>
        }
        backUrl={ROUTES.FEATURES.APPS}
      >
        {!!filteredActions?.length && (
          <Box mb="s-16">
            <MoreBar>
              {filteredActions.map(action => {
                const { text, ...actionProps } = action.props
                return (
                  <MoreBar.Action key={text} {...actionProps}>
                    {text}
                  </MoreBar.Action>
                )
              })}
            </MoreBar>
          </Box>
        )}
        {filteredTabs !== null && !hideSingleTab && (
          <Box mb="s-24">
            <TabBarWithPinning
              tabs={filteredTabs.map(t => ({ ...t, id: t.homeSectionId }))}
            />
          </Box>
        )}
      </PageHeader>
      <Flex pb="s-40" flexDirection="column" width="100%" flex={1}>
        {filteredTabs !== null && (
          <Switch>
            {filteredTabs.map(tab => {
              if (tab.component) {
                return (
                  <Route exact path={tab.path} key={tab.url}>
                    <tab.component />
                  </Route>
                )
              }
              if (tab.subtabs) {
                return (
                  <Route exact path={tab.path} key={tab.url}>
                    <TableWithSubtabs subtabs={tab.subtabs} key={tab.url} />
                  </Route>
                )
              }
              return null
            })}
          </Switch>
        )}
        {'component' in app && app.component ? <app.component /> : null}
      </Flex>
    </PageWrapper>
  )
}

export default HubApp
