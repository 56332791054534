import {
  HomeSectionId,
  Subtab,
  TabBarWithPinningTabInterface,
} from '@src/interfaces/tabPinning'
import { ROUTES } from '@src/constants/routes'
import { FeatureFlags, GlobalSettings, PermissionTypes } from '@src/store/auth/types'
import { Dashboards } from '@src/pages/Performance/Analytics/Dashboards/Dashboards'
import { QueriesTable } from '@src/apps/General/DataAnalytics/QueriesTable'
import { Goals as KPIGoals } from '@src/pages/Performance/KPI/Goals/Goals'
import { Goals } from '@src/pages/Performance/Goals/Goals'
import { Company } from '@src/pages/Performance/KPI/Company/Company'
import { Department } from '@src/pages/Performance/KPI/Department/Department'
import { ReviewsBase } from './Reviews/ReviewsBase'

const kpiTabs: Subtab[] = [
  {
    id: HomeSectionId.kpiGoals,
    title: 'Goals',
    path: ROUTES.PERFORMANCE.KPI.GOALS,
    to: ROUTES.PERFORMANCE.KPI.GOALS,
    component: KPIGoals,
  },
  {
    id: HomeSectionId.kpiCompany,
    title: 'Company',
    path: ROUTES.PERFORMANCE.KPI.COMPANY,
    to: ROUTES.PERFORMANCE.KPI.COMPANY,
    component: Company,
  },
  {
    id: HomeSectionId.kpiDepartment,
    title: 'Department',
    path: ROUTES.PERFORMANCE.KPI.DEPARTMENT,
    to: ROUTES.PERFORMANCE.KPI.DEPARTMENT,
    component: Department,
  },
]

const analyticsTabs: Subtab[] = [
  {
    id: HomeSectionId.dashboards,
    title: 'Dashboards',
    path: ROUTES.PERFORMANCE.ANALYTICS.DASHBOARDS,
    to: ROUTES.PERFORMANCE.ANALYTICS.DASHBOARDS,
    globalSetting: GlobalSettings.CommercialProductDisabled,
    component: Dashboards,
  },
  {
    id: HomeSectionId.queries,
    title: 'Queries',
    path: ROUTES.PERFORMANCE.ANALYTICS.QUERIES,
    to: ROUTES.PERFORMANCE.ANALYTICS.QUERIES,
    permission: PermissionTypes.UseReportingApp,
    component: QueriesTable,
  },
]

export const performanceSubtabs: TabBarWithPinningTabInterface[] = [
  {
    id: HomeSectionId.kpis,
    title: 'KPI',
    path: ROUTES.PERFORMANCE.KPI.ANY,
    url: ROUTES.PERFORMANCE.KPI.GOALS,
    icon: 'Target',
    subtabs: kpiTabs,
    isNewNavigation: true,
    featureFlag: FeatureFlags.CanAddKPIs,
  },
  {
    id: HomeSectionId.goals,
    title: 'Goals',
    path: ROUTES.PERFORMANCE.GOALS.ANY,
    url: ROUTES.PERFORMANCE.GOALS.GENERAL,
    icon: 'Target',
    featureFlag: FeatureFlags.CanAddGoals,
    component: Goals,
  },
  {
    id: HomeSectionId.reviews,
    title: 'Reviews',
    path: ROUTES.PERFORMANCE.REVIEWS.ANY,
    url: ROUTES.PERFORMANCE.REVIEWS.GENERAL,
    icon: 'Questionnaire',
    isNewNavigation: true,
    component: ReviewsBase,
  },
  {
    id: HomeSectionId.analytics,
    title: 'Analytics',
    path: ROUTES.PERFORMANCE.ANALYTICS.ANY,
    url: ROUTES.PERFORMANCE.ANALYTICS.DASHBOARDS,
    icon: 'BarChart',
    subtabs: analyticsTabs,
    isNewNavigation: true,
  },
]
