import { TableWidget, Token, MoreBar, MoreBarSkeleton } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useTable } from '@src/components/Table/hooks'
import {
  GoalsStats,
  ManageGoalsPayload,
  SupportedEntityGraphPath,
  fetchEntityGoalsGraph,
  goalsListTableRequests,
} from '@src/api/goals'
import {
  OrgEntityInterface,
  useOrgEntity,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'
import React, { useEffect, useMemo, useState } from 'react'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  goalsInputWeightColumn,
  goalsNameColumn,
  goalsOwnerColumn,
  goalsProgressColumn,
  goalsWeightColumn,
  goalsApprovalStatusColumn,
  goalsChangeStatusColumn,
  goalsOrgUnitColumn,
  singleTargetAdditionalColumns,
} from '@src/constants/columns/goals'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { AddGoalAction } from './components/AddGoalAction'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { BulkGoalApproveAction } from './components/BulkGoalApproveAction'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { groupFiltersIntoURLQuery } from '@src/utils/table'
import { GoalContentType, GoalKpiDetails, GoalsInterface } from '@src/interfaces/goals'
import LapeForm, { useLapeContext } from '../Form/LapeForm'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '../Form/Buttons/NewSaveButtonWithPopup'
import LapeEditableTable from '@src/components/Table/EditableTable/LapeEditableTable'
import { EditableRowInterface } from '@src/components/Table/EditableTable/EditableTable'
import { Statuses } from '@src/interfaces'
import { GoalTargetSidebarForm } from './GoalTargetSidebarForm'
import { OverallProgress } from './components/OverallProgress'
import { GoalCycleFilter } from '@src/features/Goals/components/GoalCycleFilter'
import { rowHighlight } from '../KPI'
import { PermissionTypes } from '@src/store/auth/types'
import { useManageGoalsWeights } from './useManageGoalsWeights'
import { getLocationDescriptor, navigateTo } from '@src/actions/RouterActions'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { selectPermissions } from '@src/store/auth/selectors'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { GoalsSettingsButton } from './components/GoalsSettingsButton'
import { GoalLevelSelector } from './components/GoalLevelSelector'
import { useGetSelectors } from '@src/api/selectors'
import { isNumber } from 'lodash'
import { useLocation } from 'react-router-dom'

interface GoalsTableWidgetProps {
  initialFilters: FilterByInterface[]
  entity: OrgEntityInterface
  onManageModeChange?: (enabled: boolean) => void
}

export const GoalsTableWidget = ({
  initialFilters,
  entity,
  onManageModeChange,
}: GoalsTableWidgetProps) => {
  const permissions = useSelector(selectPermissions)
  const { data: contentTypeData } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
    undefined,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  )
  const table = useTable(goalsListTableRequests, initialFilters, undefined, {
    parentIdFilterKey: 'parent_id',
  })
  const { data: performanceSettings } = useGetPerformanceSettings()
  const isMultipleGoalsTargetsEnabled =
    performanceSettings?.enable_multiple_goal_targets_per_cycle
  const form = useLapeContext<ManageGoalsPayload>()
  const contentType = table.data[0]?.content_type?.id
  const {
    manageMode,
    handleSubmit,
    weightMode,
    toggleManageMode,
    confirmationDialog,
    autoDistributeWeights,
    validationMessage,
  } = useManageGoalsWeights({
    entity,
    contentType,
    tableData: table.data,
    cycleId: table.filterBy.find(f => f.columnName === 'cycle__id')?.filters[0]?.id,
    onUpdated: () => table.refresh(),
  })
  const [selectedTarget, setSelectedTarget] = useState<GoalKpiDetails>()

  const location = useLocation<{ selectedCycle?: boolean }>()
  const initialFilterCycle = useMemo(() => {
    return initialFilters.find(({ columnName }) => columnName === 'cycle__id')
  }, [initialFilters])

  useEffect(() => {
    // We need this so that we can set the correct cycle if user decides to change cycle length
    // but we also don't want to reset cycles on "go back" to the performance page
    const directedFromCycleSelect = location?.state && location.state.selectedCycle

    if (initialFilterCycle && directedFromCycleSelect) {
      table.onFilterChange(initialFilterCycle)
    }
  }, [initialFilterCycle])

  const canManageGoals = entity.data.field_options.permissions?.includes(
    PermissionTypes.CanManageGoals,
  )
  const canSeeSettings =
    permissions.includes(PermissionTypes.ViewPerformancePreferences) &&
    (entity.type === EntityTypes.company || entity.type === EntityTypes.companyV2)

  const canImportGoals = permissions.includes(PermissionTypes.KPIAdministratorPermissions)

  const isOnboarding = isOnboardingPath()

  useEffect(() => {
    form.reset({
      ...form.values,
      goals: table.data,
    })
  }, [table.data])

  useEffect(() => {
    onManageModeChange?.(manageMode)
  }, [manageMode])

  const goalsIdsToApprove = table.data
    ?.filter(
      ({ approval_status, status }) =>
        ![Statuses.draft, Statuses.archived].includes(status.id) &&
        approval_status.id !== ApprovalStatuses.Approved,
    )
    .map(({ id }) => ({ id }))

  const getGoalUrl = (goal: GoalsInterface) => {
    const route =
      goal.status.id === Statuses.draft
        ? ROUTES.FORMS.GOAL.EDIT
        : ROUTES.FORMS.GOAL.PREVIEW
    const onboardingRoute =
      goal.status.id === Statuses.draft
        ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.EDIT
        : ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.PREVIEW

    return {
      ...getLocationDescriptor(
        pathToUrl(isOnboarding ? onboardingRoute : route, { id: goal.id }),
      ),
      search: new URLSearchParams(groupFiltersIntoURLQuery(table.filterBy)).toString(),
    }
  }

  const row = useMemo<
    EditableRowInterface<GoalsInterface> | RowInterface<GoalsInterface>
  >(() => {
    const contentId = table.filterBy.find(f => f.columnName === 'content_type_id')
      ?.filters[0]?.id
    let orgUnitFilterType: 'department' | 'team' | undefined
    if (contentId) {
      const model = contentTypeData?.find(({ id }) => id === Number(contentId))?.model
      switch (model) {
        case 'department':
          orgUnitFilterType = 'department'
          break
        case 'teams':
          orgUnitFilterType = 'team'
          break
        default:
          break
      }
    }

    return {
      highlight: goal =>
        goal.status.id === Statuses.draft || goal.status.id === Statuses.archived
          ? Token.color.background_20
          : rowHighlight(goal.approval_status.id),
      cells: [
        {
          ...goalsNameColumn(getGoalUrl),
          width: 300,
        },
        entity.type === EntityTypes.company || entity.type === EntityTypes.companyV2
          ? {
              ...goalsOrgUnitColumn({
                companyName: entity.data.name,
                type: orgUnitFilterType,
              }),
              width: 150,
            }
          : null,
        {
          ...(manageMode ? goalsInputWeightColumn : goalsWeightColumn),
          width: 70,
        },
        {
          ...goalsChangeStatusColumn((status, goalId) =>
            table.updateRows(
              r => r.id === goalId,
              r => ({ ...r, status }),
            ),
          ),
          width: 100,
        },
        {
          ...goalsProgressColumn,
          width: 100,
        },
        ...(!isMultipleGoalsTargetsEnabled ? singleTargetAdditionalColumns : []),
        {
          ...goalsOwnerColumn,
          width: 150,
        },
        {
          ...goalsApprovalStatusColumn,
          width: 50,
        },
      ].filter(Boolean),
    }
  }, [manageMode, table.data, weightMode, selectedTarget, contentTypeData])

  const entityTypePerformanceGraphPath: SupportedEntityGraphPath | undefined = {
    [EntityTypes.company]: 'company' as const,
    [EntityTypes.companyV2]: 'company' as const,
    [EntityTypes.department]: 'departments' as const,
    [EntityTypes.team]: 'teams' as const,
    [EntityTypes.teams]: 'teams' as const,
    [EntityTypes.employee]: 'employees' as const,
    [EntityTypes.employees]: 'employees' as const,
    [EntityTypes.function]: undefined,
    [EntityTypes.role]: undefined,
    [EntityTypes.specialisation]: undefined,
  }[entity.type]

  return (
    <>
      <TableWidget>
        <TableWidget.Info>
          <GoalCycleFilter
            onFilterChange={table.onFilterChange}
            columnName="cycle__id"
            selector={selectorKeys.review_cycles}
            filter={table.filterBy}
          />
          <OverallProgress
            warnAboutMandatoryGoal={
              table.stats?.mandatory_progress && isNumber(table.stats?.mandatory_progress)
                ? table.stats.mandatory_progress < 1
                : false
            }
            value={table.stats?.overall_progress}
            fetchData={
              entityTypePerformanceGraphPath
                ? fetchEntityGoalsGraph(entityTypePerformanceGraphPath)
                : undefined
            }
            id={entity?.data.id}
          />
        </TableWidget.Info>
        <TableWidget.Actions>
          {table.loading ? (
            <MoreBarSkeleton />
          ) : (
            <MoreBar>
              <BulkGoalApproveAction
                goals={goalsIdsToApprove}
                onApproved={table.refresh}
                title={
                  goalsIdsToApprove.length === table.data.length
                    ? 'Approve all goals'
                    : undefined
                }
              />
              <AddGoalAction />
              {canImportGoals && (
                <MoreBar.Action
                  useIcon="Upload"
                  onClick={() =>
                    navigateTo(
                      pathToUrl(
                        isOnboarding
                          ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.UPLOAD.IMPORT_TYPE
                          : ROUTES.FORMS.IMPORT_DATA.GOALS.IMPORT_TYPE,
                        undefined,
                      ),
                    )
                  }
                >
                  Import data
                </MoreBar.Action>
              )}
              {table.data.length > 0 && canManageGoals && (
                <MoreBar.Action
                  useIcon={manageMode ? 'SwitchOn' : 'SwitchOff'}
                  onClick={toggleManageMode}
                >
                  Edit weights
                </MoreBar.Action>
              )}
              {manageMode && (
                <MoreBar.Action useIcon="AutoExchange" onClick={autoDistributeWeights}>
                  Auto-distribute weights
                </MoreBar.Action>
              )}
              {canSeeSettings && <GoalsSettingsButton />}
            </MoreBar>
          )}
        </TableWidget.Actions>
        {[EntityTypes.company, EntityTypes.companyV2].includes(entity.type) && (
          <TableWidget.Filters>
            <GoalLevelSelector
              cycleFilter={table.filterBy.find(f => f.columnName === 'cycle__id')}
              onFilterChange={table.onFilterChange}
            />
          </TableWidget.Filters>
        )}
        <TableWidget.Table>
          {manageMode ? (
            <LapeEditableTable<GoalsInterface>
              dataFieldName="goals"
              name={TableNames.Goals}
              dataType="Goal"
              disableFilters={manageMode}
              {...table}
              initialData={table.data}
              row={row}
              useWindowScroll
              replaceOnInitialDataChange
            />
          ) : (
            <AdjustableTable<GoalsInterface, GoalsStats>
              name={TableNames.Goals}
              dataType="Goal"
              {...table}
              row={row as RowInterface<GoalsInterface>}
              expandableType="chevron"
              useFetchedChildren
              useWindowScroll
            />
          )}

          {manageMode && !table.loading && table.data.length ? validationMessage : null}
        </TableWidget.Table>
      </TableWidget>
      {manageMode && form.dirty && (
        <PageActions my="s-32" maxWidth="100%">
          <NewSaveButtonWithPopup
            disabled={!!validationMessage}
            onClick={() => handleSubmit()}
            successText="Goal weights have been updated"
          >
            Submit
          </NewSaveButtonWithPopup>
        </PageActions>
      )}
      <GoalTargetSidebarForm
        target={selectedTarget}
        onClose={() => setSelectedTarget(undefined)}
        onAfterSubmit={() => {
          table.refresh()
          setSelectedTarget(undefined)
        }}
      />
      {confirmationDialog}
    </>
  )
}

export const GoalsTableFormWidget = ({
  initialFilters,
  onManageModeChange,
}: {
  initialFilters: FilterByInterface[]
  onManageModeChange?: (enabled: boolean) => void
}) => {
  const { entity } = useOrgEntity()

  const initialValues = useMemo(() => {
    return entity && 'goal_weight_mode' in entity.data && entity.data.goal_weight_mode
      ? {
          goals: [],
          goal_weight_mode: entity.data.goal_weight_mode,
        }
      : {
          goals: [],
          goal_weight_mode: { id: 'automatic' },
        }
  }, [entity])

  return entity ? (
    <LapeForm
      disableValidation
      onSubmit={() => Promise.resolve({})}
      initialValues={initialValues}
    >
      <GoalsTableWidget
        initialFilters={initialFilters}
        entity={entity}
        onManageModeChange={onManageModeChange}
      />
    </LapeForm>
  ) : null
}
