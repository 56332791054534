import React from 'react'
import { Box, ErrorWidget, ItemSkeleton } from '@revolut/ui-kit'
import {
  HiringProcessInterface,
  SpecialisationHiringProcess,
} from '@src/interfaces/hiringProccess'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { HiringStage } from '@src/pages/Forms/SpecialisationHiringProcess/components/HiringStage'

type RoleHiringStagesProps = {
  disabled: boolean
  hiringStages?: HiringProcessInterface[]
  loadingHiringStages: boolean
  selected: HiringProcessInterface[]
  onChangeSelected: (selected: HiringProcessInterface[]) => void
}

export const RoleHiringStages = ({
  disabled,
  hiringStages,
  loadingHiringStages,
  selected,
  onChangeSelected,
}: RoleHiringStagesProps) => {
  const { values } = useLapeContext<SpecialisationHiringProcess>()
  return (
    <Box>
      {hiringStages?.map(hiringStage => {
        return (
          <HiringStage
            key={hiringStage.id}
            hiringStage={hiringStage}
            disabled={disabled}
            selected={selected}
            onSelect={onChangeSelected}
          />
        )
      })}
      {loadingHiringStages && <ItemSkeleton />}
      {!loadingHiringStages && !hiringStages?.length && (
        <Box my="s-8">
          <ErrorWidget>
            <ErrorWidget.Image />
            <ErrorWidget.Title>
              No Role hiring stages found for {values.specialisation.name}
            </ErrorWidget.Title>
          </ErrorWidget>
        </Box>
      )}
    </Box>
  )
}
